<script lang="ts">
import { usePermissions } from '~/composables/usePermissions';
import AppLoadingSpinner from '~/components/AppLoadingSpinner.vue';
import { useNuxtApp } from '#imports';
import AppFatalError from '~/components/AppFatalError.vue';
import {useUser} from "~/store/user";
import {setIdentity, useFlag} from "~/plugins/flagsmith";
import MigrationStatusToast from "~/components/Migration/MigrationStatusToast.vue";
import AppEnvironmentInfo from "~/components/AppEnvironmentInfo.vue";
import {getCurrentEnvironment} from "~/utils/environment";
import {Ref} from "@vue/reactivity";
import type {MenuItem} from "primevue/menuitem";
import {AppPermissions} from "~/types/Permissions";

export default defineComponent({
  components: {AppEnvironmentInfo, MigrationStatusToast, AppFatalError, AppLoadingSpinner },
  setup() {
    if (process.client) {
      onBeforeMount(() => {
        setIdentity(useUser().getTenant?.username);
      })

      const userStore = useUser();
      watch(() => userStore.getTenant, ((value, oldValue) => {
        setIdentity(value?.username);
      }), { deep: true });

      if (useUser().getTenant?.username) {
        setIdentity(useUser().getTenant?.username);
        console.log('setIdentity', useUser().getTenant?.username)
      }
    }

    const route = useRoute();

    useHead({
      titleTemplate: (title) => {
        if (route.path.startsWith('/settings')) {
          return title ? `${title} - Einstellungen | Fynn` : 'Einstellungen | Fynn Billing Platform';
        }
        return title ? `${title} | Fynn` : 'Fynn Billing Platform';
      },
    });

    const menu = computed(() => {
      const userStore = useUser();

      const sakaiNuxt = {
        name: 'Fynn Finance Platform',

        logo: {
          dark: '/images/logo-white.svg',
          light: '/images/logo-dark.svg',
        },

        sidebarMenuSettings: (can: Function, features: {
          offerCrm: Ref<boolean>,
          dunningBasic: Ref<boolean>,
          migrationAssistant: Ref<boolean>,
          accountingBasic: Ref<boolean>,
          getStartedFinished: Ref<boolean>,
        }): MenuItem[] => [
          {
            items: [
              {
                label: 'Zurück',
                icon: 'pi pi-fw pi-arrow-left',
                to: features.getStartedFinished.value ? '/' : '/first-steps',
              },
            ],
          },
          {
            label: 'Einstellungen',
            visible: can(AppPermissions.RoleAdministrator),
            items: [
              {
                label: 'Allgemein',
                to: '/settings',
                exact: true,
                icon: 'pi pi-fw pi-cog',
              },
              {
                label: 'Benutzer',
                to: '/settings/users',
                icon: 'pi pi-fw pi-users',
                visible: can(AppPermissions.TenantAccountRead),
              },
              {
                label: 'Nummernkreise',
                to: '/settings/number-ranges',
                icon: 'pi pi-fw pi-list',
                visible: can(AppPermissions.NumberSettingRead),
              },
              {
                label: 'Zahlungsmethoden',
                to: '/settings/payment-methods',
                icon: 'pi pi-fw pi-credit-card',
              },
              {
                label: 'Belege',
                to: '/settings/invoices',
                icon: 'pi pi-fw pi-book',
              },
              {
                label: 'Mahnwesen',
                to: '/settings/payment-failures',
                icon: 'pi pi-fw pi-exclamation-triangle',
                visible: features.dunningBasic.value,
              },
              {
                label: 'Hosted Pages',
                to: '/settings/hosted-services',
                icon: 'pi pi-fw pi-globe',
              },
              {
                label: 'Steuern',
                to: '/settings/taxes',
                visible: false,
              },
              {
                label: 'Benachrichtigungen',
                to: '/settings/notifications',
                icon: 'pi pi-fw pi-bell',
                visible: can(AppPermissions.NotificationSettingRead),
              },
              {
                label: 'Buchhaltung',
                to: '/settings/accounting/cost-centres',
                icon: 'pi pi-fw pi-calculator',
                visible: features.accountingBasic.value,
              },
              {
                label: 'CRM',
                to: '/settings/crm',
                icon: 'pi pi-fw pi-users',
                visible: features.offerCrm.value,
              },
              {
                label: 'Entwickler',
                to: '/settings/developer',
                icon: 'pi pi-fw pi-code',
              },
              {
                label: 'Migrations-Assistent',
                to: '/settings/migrations',
                icon: 'pi pi-fw pi-sync',
                visible: features.migrationAssistant.value,
              }
            ]
          }
        ],

        sidebarMenu: (can: Function, features: {
          offerBasic: Ref<boolean>,
          dunningBasic: Ref<boolean>,
          paymentGatewaySepa: Ref<boolean>,
          accountingBasic: Ref<boolean>,
          customerPartner: Ref<boolean>,
          priceUpdateAssistant: Ref<boolean>,
          cashflow: Ref<boolean>,
          getStartedFinished: Ref<boolean>,
        }) => [
          {
            label: 'Home',
            items: [
              {
                label: 'Erste Schritte',
                icon: 'pi pi-fw pi-check',
                to: '/first-steps',
                visible: !features.getStartedFinished.value,
              },
              {
                label: 'Dashboard',
                icon: 'pi pi-fw pi-home',
                to: '/',
              },
            ],
          },
          {
            label: 'Verkäufe',
            items: [
              {
                label: 'Partner',
                icon: 'pi pi-fw pi-users',
                to: '/partners',
                visible: can(AppPermissions.PartnerRead) && features.customerPartner.value,
                disabled: !can(AppPermissions.PartnerRead) && features.customerPartner.value,
              },
              {
                label: 'Kunden',
                icon: 'pi pi-fw pi-user',
                to: '/customers',
                visible: can(AppPermissions.CustomerRead),
                disabled: !can(AppPermissions.CustomerRead),
              },
              {
                label: 'Abonnements',
                icon: 'pi pi-fw pi-folder',
                to: '/subscriptions',
                visible: can(AppPermissions.SubscriptionRead),
                disabled: !can(AppPermissions.SubscriptionRead),
              },
              {
                label: 'Bestellungen',
                icon: 'pi pi-fw pi-shopping-cart',
                to: '/orders',
                visible: can(AppPermissions.CartRead),
              },
              {
                label: 'Angebote',
                icon: 'pi pi-fw pi-file',
                to: '/offers',
                visible: features.offerBasic.value && can(AppPermissions.OfferRead),
              },
            ],
          },
          {
            label: 'Zahlungen',
            visible: can(AppPermissions.InvoiceRead),
            disabled: !can(AppPermissions.InvoiceRead),
            items: [
              {
                label: 'Belege',
                icon: 'pi pi-fw pi-book',
                items: [
                  {
                    label: 'Rechnungen',
                    to: '/invoices',
                    visible: can(AppPermissions.InvoiceRead),
                  },
                  {
                    label: 'Gutschriften',
                    to: '/invoices/credits',
                    visible: usePermissions().inDevelopment(),
                  },
                  {
                    label: 'Stornos',
                    to: '/invoices/cancels',
                    visible: can(AppPermissions.InvoiceRead),
                  },
                  {
                    label: 'Zahlungsverzug',
                    to: '/dunning/documents',
                    visible: features.dunningBasic.value && can(AppPermissions.DunningDocumentRead),
                  },
                ],
              },
              {
                label: 'Zahlungen',
                icon: 'pi pi-fw pi-euro',
                to: '/finance/transactions',
                visible: can(AppPermissions.TransactionRead),
                disabled: !can(AppPermissions.TransactionRead),
              },
              {
                label: 'SEPA Aufträge',
                icon: 'pi pi-fw pi-file-export',
                to: '/finance/sepa-exports',
                visible: features.paymentGatewaySepa.value && can(AppPermissions.SepaXmlRead),
                disabled: !can(AppPermissions.SepaXmlRead),
              },
              {
                label: 'Kontobewegungen',
                icon: 'pi pi-fw pi-wallet',
                to: '/finance/bank-transactions',
                visible: can(AppPermissions.BankAccountTransactionRead),
                disabled: !can(AppPermissions.BankAccountTransactionRead),
              },
              {
                label: 'Buchungen',
                icon: 'pi pi-fw pi-check-square',
                visible: features.accountingBasic.value,
                items: [
                  {
                    label: 'Buchungssätze',
                    to: '/accounting/postings',
                  },
                  {
                    label: 'Exporte',
                    to: '/accounting/export',
                  },
                  {
                    label: 'DATEV',
                    to: '/accounting/datev',
                  },
                  // {
                  //   label: 'Buchungskreise', icon: 'pi pi-fw pi-tags', to: '/payment/booking-circles',
                  // },
                  // {
                  //   label: 'Buchungskreis-Regeln', icon: 'pi pi-fw pi-tags', to: '/payment/booking-circle-rules',
                  // }
                ],
              },
            ],
          },
          {
            label: 'Produktkatalog',
            items: [
              {
                label: 'Produkte',
                icon: 'pi pi-fw pi-box',
                visible: can(AppPermissions.ProductRead),
                disabled: !can(AppPermissions.ProductRead),
                items: [
                  {
                    label: 'Einzelprodukte',
                    icon: 'pi pi-fw pi-list',
                    to: '/catalogue/products',
                    visible: can(AppPermissions.ProductRead),
                  },
                  {
                    label: 'Kategorien',
                    icon: 'pi pi-fw pi-tags',
                    to: '/catalogue/product-families',
                    visible: can(AppPermissions.ProductFamilyRead),
                    disabled: !can(AppPermissions.ProductFamilyRead),
                  },
                  {
                    label: 'Einheiten',
                    icon: 'pi pi-fw pi-tags',
                    to: '/catalogue/units',
                    visible: can(AppPermissions.MeasurementRead),
                    disabled: !can(AppPermissions.MeasurementRead),
                  },
                ],
              },
              {
                label: 'Pläne',
                icon: 'pi pi-fw pi-sliders-h',
                visible: can(AppPermissions.PlanRead) && usePermissions().inDevelopment(),
                disabled: !can(AppPermissions.PlanRead),
                items: [
                  {
                    label: 'Individuelle Pläne',
                    icon: 'pi pi-fw pi-list',
                    to: '/catalogue/plans',
                  },
                  {
                    label: 'Planfamilien',
                    icon: 'pi pi-fw pi-tags',
                    to: '/catalogue/plan-families',
                    disabled: true,
                  },
                ],
              },
              {
                label: 'Preis Assistent',
                icon: 'pi pi-fw pi-money-bill',
                to: '/catalogue/price-updates',
                visible: can(AppPermissions.PriceUpdateBulkRead) && features.priceUpdateAssistant.value,
              },
              {
                label: 'Rabatte',
                icon: 'pi pi-fw pi-percentage',
                to: '/catalogue/vouchers',
                visible: can(AppPermissions.CouponRead),
              },
            ],
          },
          {
            label: 'Cashflow',
            visible: features.cashflow.value,
            items: [
              {
                label: 'Übersicht',
                icon: 'pi pi-fw pi-chart-line',
                to: '/cashflow',
              },
              {
                label: 'Kategorien',
                icon: 'pi pi-fw pi-list',
                to: '/cashflow/categories',
                //visible: can(AppPermissions.CashflowCategoryRead),
              },
              {
                label: 'Transaktionen',
                icon: 'pi pi-fw pi-euro',
                to: '/cashflow/transactions',
              },
              {
                label: 'Regeln',
                icon: 'pi pi-fw pi-cog',
                to: '/cashflow/rules',
              },
              {
                label: 'Budgets',
                icon: 'pi pi-fw pi-chart-bar',
                to: '/cashflow/budgets',
                //visible: can(AppPermissions.CashflowBudgetRead),
              },
            ],
          },
          {
            label: 'Erste Schritte',
            items: [
              {
                label: 'Dokumentation',
                icon: 'pi pi-fw pi-question',
                command: () => {
                  const a = document.createElement('a');
                  a.href = 'https://docs.fynn.dev';
                  a.target = '_blank';
                  a.click();
                  a.remove();
                },
              },
              { label: 'Support', icon: 'pi pi-fw pi-comments', to: 'mailto:support@fynn.eu' },
            ],
          },
          // ...demoPages
        ],
      };

      if (route.path.startsWith('/settings')) {
        return sakaiNuxt.sidebarMenuSettings(usePermissions().can, {
          offerCrm: useFlag('offer.crm'),
          dunningBasic: useFlag('dunning.basic'),
          migrationAssistant: useFlag('migration.assistant'),
          accountingBasic: useFlag('accounting.basic'),
          getStartedFinished: computed(() => userStore.getStartedFinished),
        });
      }

      return sakaiNuxt.sidebarMenu(usePermissions().can, {
        offerBasic: useFlag('offer.basic'),
        dunningBasic: useFlag('dunning.basic'),
        paymentGatewaySepa: useFlag('payment-gateway.sepa'),
        accountingBasic: useFlag('accounting.basic'),
        customerPartner: useFlag('customer.partners'),
        priceUpdateAssistant: useFlag('price.update-assistant'),
        cashflow: useFlag('cashflow'),
        getStartedFinished: computed(() => userStore.getStartedFinished),
      });
    });
    const pageIsLoading = ref(true);

    const nuxtApp = useNuxtApp();

    nuxtApp.hook('page:finish', () => {
      pageIsLoading.value = false;
    });

    nuxtApp.hook('app:error', (error: Error) => {
      console.log('[FYNN] app:error');
      console.error(error);
    });

    if (process.browser) {
      document.addEventListener('DOMContentLoaded', () => {
        setTimeout(() => {
          pageIsLoading.value = false;
        }, 100);
      });
    }

    const isSettingsPage = computed(() => route.path.startsWith('/settings'));

    return {
      menu,
      pageIsLoading,
      isSettingsPage,
    };
  },
  data() {
    return {
      layoutMode: 'static',
      menuActive: false,
      menuClick: false,
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      showSidebar: true,
    };
  },
  provide() {
    return {
      isSidebarful: computed(() => this.showSidebar),
    };
  },
  computed: {
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
          'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false,
          'layout-theme-light': this.$appState.theme?.startsWith('saga'),
          'layout-sidebar-hidden': !this.showSidebar,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme ? '/images/logo-white.svg' : '/images/logo.svg';
    },
    isCrmIframe() {
      return this.$route.query?.crmIframe === 'true';
    },
    currentEnvironment() {
      const current = getCurrentEnvironment();
      if (current === 'production') {
        return null;
      }

      return current;
    }
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive) {
      this.addClass(document.body, 'body-overflow-hidden');
    } else {
      this.removeClass(document.body, 'body-overflow-hidden');
    }
  },
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle(event: Event) {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event: any) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode: string) {
      this.layoutMode = layoutMode;
    },
    addClass(element: Element, className: string) {
      if (element.classList) {
        element.classList.add(className);
      } else {
        element.className += ` ${className}`;
      }
    },
    removeClass(element: Element, className: string) {
      if (element.classList) {
        element.classList.remove(className);
      } else {
        element.className = element.className.replace(new RegExp(`(^|\\b)${className.split(' ').join('|')}(\\b|$)`, 'gi'), ' ');
      }
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static') {
          return !this.staticMenuInactive;
        } else if (this.layoutMode === 'overlay') {
          return this.overlayMenuActive;
        }
      }

      return true;
    },
  },
});
</script>

<template>
  <div :class="containerClass" @click="onWrapperClick">
    <div v-if="pageIsLoading" class="fixed top-0 left-0 bg-app-white w-full h-screen overflow-hidden" style="z-index: 1001">
      <div class="flex w-full h-screen items-center justify-center bg-gradient-to-tr from-slate-50 to-[#ECFBFF]">
        <div class="flex items-center justify-center gap-4 flex-col">
          <video src="/images/fynn-loader.webm" type="video/webm" playsinline autoplay muted loop class="w-16 mx-auto" />
        </div>
      </div>
    </div>

    <ClientOnly>
      <AppEnvironmentInfo />
    </ClientOnly>

    <AppTopbar @menu-toggle="onMenuToggle" v-if="!isCrmIframe" />
    <div v-if="!isCrmIframe" :class="{
      'mt-1': currentEnvironment !== null,
    }">
      <div class="lg:block hidden fixed bg-gray-50 rounded-tr-md rounded-br-md p-1 top-16 cursor-pointer transition-all" @click="toggleSidebar" style="z-index: 1000;" :class="{
        'left-[260px]' : showSidebar,
        'left-[60px]' : !showSidebar,
      }">
        <i class="pi pi-chevron-left" v-if="showSidebar" />
        <i class="pi pi-chevron-right" v-else />
      </div>
      <div class="layout-sidebar layout-sidebar-light flex flex-col transition-all" @click="onSidebarClick" :class="{
        '!w-[260px] layout-sidebar-full': showSidebar,
        '!w-[60px] layout-sidebar-icons-only': !showSidebar,
        'layout-sidebar-dark': isSettingsPage
      }">
        <div class="mb-4 sticky top-0 pt-4 z-10 pb-2">
          <NuxtLink to="/" class="layout-topbar-logo flex items-center justify-center pb-4">
            <AppLogo class="w-[6rem] py-3" :dark="isSettingsPage" />
          </NuxtLink>
          <client-only>
            <AppTenantSelector />
          </client-only>
        </div>
        <client-only>
          <AppMenu :model="menu" class="pb-5" @menuitem-click="onMenuItemClick" />
        </client-only>

        <div v-if="false" class="bg-gray-50 p-3 sticky bottom-0 -mx-4 -mb-8">
          <p>Wähle einen Plan aus, um deinen Account zu aktivieren.</p>

          <Button type="button" class="w-full" label="Kostenlos testen" />
        </div>
      </div>
    </div>

    <div class="layout-main-container" :class="{
      'layout-main-container-crm': isCrmIframe,
    }">
      <div class="layout-main bg-app-white flex flex-col">
        <slot />
      </div>
      <AppFooter />
    </div>

    <transition name="layout-mask">
      <div v-if="mobileMenuActive" class="layout-mask p-component-overlay" />
    </transition>
    <div class="md:col-span-3 md:col-span-2 md:grid-cols-5"></div>
    <client-only>
      <AppFatalError />
      <!--<MigrationStatusToast />-->
    </client-only>
  </div>
</template>

<style lang="scss">
@import './../assets/styles/App.scss';

.layout-sidebar-hidden {
  .layout-main-container {
    margin-left: 60px !important;
  }

  .settings-item--label {
    width: 35%;
  }
}

.layout-main-container-crm {
  margin-left: 0 !important;
}

.layout-sidebar-icons-only {
  @apply px-3 overflow-hidden;

  .tenant-selector {
    opacity: 0;
  }

  .layout-menu li ul ul {
    padding-left: 0;
    @apply my-1;
  }

  ul > li {
    @apply mb-1;
  }

  .menuitem-toggle-icon {
    display: none;
  }

  .router-link-active:not(.layout-topbar-logo) {
    > i {
      color: var(--text-black) !important;
      font-weight: 700;
    }

    & {
      background-color: var(--surface-ground);
    }
  }

  .layout-menuitem-category {
    ul > li > a > span {
      display: none;
    }
  }

  .layout-menuitem-root-text {
    display: none !important;
  }
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition:
    opacity 0.5s ease,
    transform 0.5s ease,
    height 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: translateX(10px);
}

.wave {
  position: absolute;
  width: 100%;
  padding-top: 12%;
  top: -5px;
  left: 0;
  background-image: url('/images/wave_small.svg');
  background-repeat: repeat-x;
  background-size: 100%;
  animation: wave 4s linear infinite;
}
.wave::before {
  content: '';
  position: absolute;
  width: 100%;
  padding-top: 12%;
  top: -5px;
  left: 0;
  background-image: url('/images/wave_black.svg');
  background-repeat: repeat-x;
  background-size: 100%;
  opacity: 0.2;
  animation: wave-reverse 4s linear infinite;
}
.wave::after {
  content: '';
  position: absolute;
  width: 100%;
  padding-top: 12%;
  top: -5px;
  left: 0;
  background-image: url('/images/wave_small.svg');
  background-repeat: repeat-x;
  background-size: 100%;
  opacity: 0.8;
  animation-delay: -4s;
  animation: wave 8s linear infinite;
}
@keyframes wave {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 100vw;
  }
}
@keyframes wave-reverse {
  0% {
    background-position: 100vw;
  }
  100% {
    background-position: 0;
  }
}
</style>
